
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingCategory, ListingStatus, PropertyType } from 'client-website-ts-library/types';
import { API, Logger, LogLevel } from 'client-website-ts-library/services';

@Component({
  components: {
    MultiSelect,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ default: 'buy' })
  private readonly methodOfSale!: string;

  @Prop()
  private readonly navigate!: boolean;

  @Prop()
  private readonly type!: string;

  @Prop({ default: true })
  private readonly showPropertyTypes!: boolean;

  private showAdvancedSearch = true;

  private searchType: string = this.type;

  private minPrice = '0';

  private maxPrice = '0';

  private minBeds = '0';

  private minBaths = '0';

  private minCars = '0';

  private commercialAreaMin = '0';

  private commercialAreaMax = '0';

  private moneyFormatter = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 0 });

  private hasLand = false;

  private mos: string = this.methodOfSale || 'buy';

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    PageSize: 9,
  });

  private suburbs: MultiSelectOption[] = [];

  private selectedSuburbs: MultiSelectOption[] = [];

  private propertyTypeOptions: MultiSelectOption[] = [
    { Value: PropertyType.House.toString(), Label: 'House' },
    { Value: PropertyType.Apartment.toString(), Label: 'Apartment' },
    { Value: PropertyType.Unit.toString(), Label: 'Unit' },
    { Value: PropertyType.Townhouse.toString(), Label: 'Townhouse' },
    { Value: PropertyType.Villa.toString(), Label: 'Villa' },
    { Value: PropertyType.BlockOfUnits.toString(), Label: 'Block Of Units' },
    { Value: PropertyType.Studio.toString(), Label: 'Studio' },
    { Value: PropertyType.Retirement.toString(), Label: 'Retirement Living' },
    { Value: PropertyType.Acreage.toString(), Label: 'Acreage' },
    { Value: 'Land', Label: 'Land' },
  ];

  mounted() {
    try {
      this.minBeds = this.$route.query.minBeds ? (this.$route.query.minBeds as string) : '0';
      this.minBaths = this.$route.query.minBaths ? (this.$route.query.minBaths as string) : '0';
      this.minCars = this.$route.query.minCars ? (this.$route.query.minCars as string) : '0';

      this.minPrice = this.$route.query.minPrice ? (this.$route.query.minPrice as string) : '0';
      this.maxPrice = this.$route.query.maxPrice ? (this.$route.query.maxPrice as string) : '0';
    } catch (ex) {
      Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse beds, baths, min price and max price from query');
    }

    this.updateSuburbs().then(() => {
      try {
        if (this.$route.query.suburbs) {
          const suburbs = (this.$route.query.suburbs as string).split('|');
          (this.$refs.SuburbMultiSelect as MultiSelect).setValues(suburbs);
          this.filter!.Suburbs = suburbs;
        }
      } catch (ex) {
        console.error(ex);
        Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse suburb multi select from query');
      }
    });

    try {
      if (this.$route.query.propertyTypes) {
        const propertyTypes = (this.$route.query.propertyTypes as string).split('|').join(',');

        const selectedFilterTypes: PropertyType[] = [];
        const selectedDropdownTypes: string[] = [];

        this.propertyTypeOptions.forEach((typeOption) => {
          if (propertyTypes.indexOf(typeOption.Value) !== -1) {
            selectedFilterTypes.push(...typeOption.Value.split(',').map((p) => parseInt(p, 10) as PropertyType));
            selectedDropdownTypes.push(typeOption.Value);
          }
        });

        this.hasLand = selectedDropdownTypes.some((s) => s === 'Land') ?? false;
        this.filter!.PropertyTypes = selectedFilterTypes;
        (this.$refs.PropertyTypeMultiSelect as MultiSelect).setValues(selectedDropdownTypes);
      }
    } catch (ex) {
      Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse property type multi select from query');
    }

    this.updateFilter();
  }

  @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale || 'buy';
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  /* updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce);

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
        this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }));
      });
    }, 2000);
  } */

  private updateSuburbCallbacks: ((result: (void | PromiseLike<void>)) => void)[] = []

  updateSuburbs(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.filter) return;

      this.updateSuburbCallbacks.push(resolve);

      clearTimeout(this.updateSuburbsDebounce);

      this.updateSuburbsDebounce = window.setTimeout(() => {
        const tmpFilter = new ListingFilter(this.filter);

        tmpFilter.Suburbs = [];

        API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
          this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }));

          const callbacks = [...this.updateSuburbCallbacks];
          this.updateSuburbCallbacks = [];

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < callbacks.length; i++) {
            callbacks[i]();
          }
        });
      }, 2000);
    });
  }

  private hasUpdated = false

  @Watch('mos')
  clearPrice() {
    this.filter.MinPrice = this.minPrice;
    this.filter.MaxPrice = this.maxPrice;
    this.minPrice = '0';
    this.maxPrice = '0';
  }

  @Watch('mos')
  updateFilter(onPage = false) {
    this.searchType = this.mos;

    switch (this.mos) {
      case 'buy':
        if (this.filter.PropertyTypes && this.filter.PropertyTypes.length === 1 && this.hasLand) {
          this.filter.Categories = [ListingCategory.ResidentialLand];
        } else if (this.filter.PropertyTypes && this.filter.PropertyTypes.length > 1 && this.hasLand) {
          this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural];
        } else if (this.filter.PropertyTypes?.some((s) => s === 0)) {
          this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.Rural];
        } else if (this.filter.PropertyTypes.length > 0) {
          this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.Rural];
        } else {
          this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural];
        }
        this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
        break;
      case 'rent':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ];
        this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
        break;
      case 'sold':
        this.filter.Statuses = [
          ListingStatus.Sold,
        ];
        this.filter.OrderByStatements = [ListingOrderBy.SoldDateDesc];
        break;
      default:
        this.filter.Categories = [];
        this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
        break;
    }

    if (this.minPrice && this.minPrice !== '0') this.filter.MinPrice = this.minPrice;
    if (this.maxPrice && this.maxPrice !== '0') this.filter.MaxPrice = this.maxPrice;

    if (this.minBeds && this.minBeds !== '0') this.filter.MinBedrooms = this.minBeds;
    if (this.minBaths && this.minBaths !== '0') this.filter.MinBathrooms = this.minBaths;
    if (this.minCars && this.minCars !== '0') this.filter.MinBathrooms = this.minCars;

    this.updateSuburbs();

    if (onPage) {
      this.filter.Suburbs = this.selectedSuburbs.map((s) => s.Value);
    } else {
      this.filter.Suburbs = this.$route.query.suburbs ? this.$route.query.suburbs as string[] : [];
    }

    this.filter.Page = 1;

    if (this.filter.Statuses.length === 1 && this.filter.Statuses[0] === ListingStatus.Sold) {
      this.filter.OrderByStatements = [ListingOrderBy.SoldDateDesc];
    } else {
      this.filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
    }

    this.hasUpdated = true;

    this.emitFilter();
  }

  suburbsUpdated(selectedSuburbs: MultiSelectOption[]) {
    this.selectedSuburbs = selectedSuburbs;
    this.updateFilter();
  }

  propertyTypesUpdated(selectedPropertyTypes: MultiSelectOption[]) {
    if (!this.filter) return;

    this.filter.PropertyTypes = [];

    for (let i = 0; i < selectedPropertyTypes.length; i += 1) {
      // eslint-disable-next-line radix
      const types = selectedPropertyTypes[i].Value.split(',').map((v: string) => parseInt(v));

      this.filter.PropertyTypes.push(...types);
    }

    this.hasLand = selectedPropertyTypes.some((s) => s.Value === 'Land') ?? false;

    console.log(this.filter.PropertyTypes);
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.$emit('filter_updated', this.filter);
  }

  toggleAdvancedSearch() {
    console.log(this.showAdvancedSearch);
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  doSearch() {
    if (this.navigate) {
      this.updateFilter(true);
      switch (this.mos) {
        case 'buy':
          this.$router.push({
            path: '/properties-for-sale',
            query: {
              propertyTypes: this.filter.PropertyTypes.join('|') + this.hasLand ? 'Land' : '',
              /* suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minCars: this.minCars.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(), */
            },
          });
          break;
        case 'rent':
          this.$router.push({
            path: '/properties-for-rent',
            query: {
              propertyTypes: this.filter.PropertyTypes.join('|') + this.hasLand ? 'Land' : '',
              /* suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minCars: this.minCars.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(), */
            },
          });
          break;
        case 'sold':
          this.$router.push({
            path: '/properties-sold',
            query: {
              propertyTypes: this.filter.PropertyTypes.join('|') + this.hasLand ? 'Land' : '',
              /* suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minCars: this.minCars.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(), */
            },
          });
          break;
        default:

          break;
      }
    } else {
      // REPLACE URL
      this.updateFilter(true);
      switch (this.mos) {
        case 'buy':
          this.$router.replace({
            path: '/properties-for-sale',
            query: {
              propertyTypes: this.filter.PropertyTypes.join('|') + this.hasLand ? 'Land' : '',
              /* suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minCars: this.minCars.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(), */
            },
          });
          break;
        case 'rent':
          this.$router.replace({
            path: '/properties-for-rent',
            query: {
              propertyTypes: this.filter.PropertyTypes.join('|') + this.hasLand ? 'Land' : '',
              /* suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minCars: this.minCars.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(), */
            },
          });
          break;
        case 'sold':
          this.$router.replace({
            path: '/properties-sold',
            query: {
              propertyTypes: this.filter.PropertyTypes.join('|') + this.hasLand ? 'Land' : '',
              /* suburbs: this.filter.Suburbs.join('|'),
              minBeds: this.minBeds.toString(),
              minBaths: this.minBaths.toString(),
              minCars: this.minCars.toString(),
              minPrice: this.minPrice.toString(),
              maxPrice: this.maxPrice.toString(), */
            },
          });
          break;
        default:

          break;
      }
    }
  }
}
